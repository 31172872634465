<template>
  <div class="dashboard-editor-container">
    <PanelGroup />
    <el-row :gutter="40">
      <el-col
        :xs="12"
        :sm="12"
        :lg="12"
      >
        <div class="chart-wrapper">
          <div class="chart-wrapper-nav">
            <p>Revenue Trend</p>
            <DaysSelect
              v-model:dateRangeArr="revenueDaysSelect"
            />
          </div>
          <LineChartRevenue
            :selectDate="revenueDaysSelect"
          />
        </div>
      </el-col>
      <el-col
        :xs="12"
        :sm="12"
        :lg="12"
      >
        <div class="chart-wrapper">
          <div class="chart-wrapper-nav">
            <p>Member Trend</p>
            <DaysSelect
              v-model:dateRangeArr="memberDaysSelect"
            />
          </div>
          <LineChartMember
            :selectDate="memberDaysSelect"
          />
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="40">
      <el-col
        :xs="24"
        :sm="24"
        :lg="24"
      >
        <div class="chart-wrapper">
          <p>RealTime User</p>
          <LineChartRealTimeUser />
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="40">
      <el-col
        :xs="24"
        :sm="24"
        :lg="24"
      >
        <div class="chart-wrapper">
          <p> RealTime Revenue</p>
          <LineChartRealTimeRevenue />
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="40">
      <el-col
        :xs="12"
        :sm="12"
        :lg="12"
      >
        <div class="chart-wrapper">
          <PieChartVisitor />
        </div>
      </el-col>
      <el-col
        :xs="12"
        :sm="12"
        :lg="12"
      >
        <div class="chart-wrapper">
          <PieChartSubscriber />
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="40">
      <el-col
        :xs="12"
        :sm="12"
        :lg="12"
      >
        <div class="chart-wrapper">
          <div>
            <div class="chart-wrapper-nav">
              <p>Channel List</p>
              <DaysSelect
                v-model:dateRangeArr="channelDaysSelect"
              />
            </div>
          </div>
          <ChannelList
            :selectDate="channelDaysSelect"
          />
        </div>
      </el-col>
      <el-col
        :xs="12"
        :sm="12"
        :lg="12"
      >
        <div class="chart-wrapper">
          <div class="chart-wrapper-nav">
            <p>Plans & Subscribers Count List</p>
            <DaysSelect
              v-model:dateRangeArr="subscribersDaysSelect"
            />
          </div>
          <PlanList
            :selectDate="subscribersDaysSelect"
          />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref } from 'vue';
import PanelGroup from './components/PanelGroup.vue';
import LineChartRealTimeUser from './components/LineChartRealTimeUser.vue';
import LineChartRealTimeRevenue from './components/LineChartRealTimeRevenue.vue';
import LineChartRevenue from './components/LineChartRevenue.vue';
import LineChartMember from './components/LineChartMember.vue';
import PieChartVisitor from './components/PieChartVisitor.vue';
import PieChartSubscriber from './components/PieChartSubscriber.vue';
import ChannelList from './components/ChannelList.vue';
import PlanList from './components/PlanList.vue';
import DaysSelect from './components/daysSelect.vue';

export default {
  components: {
    PanelGroup,
    LineChartRevenue,
    LineChartMember,
    PieChartVisitor,
    PieChartSubscriber,
    ChannelList,
    PlanList,
    LineChartRealTimeUser,
    LineChartRealTimeRevenue,
    DaysSelect
  },
  setup() {
    const revenueDaysSelect = ref([]);
    const memberDaysSelect = ref([]);
    const channelDaysSelect = ref([]);
    const subscribersDaysSelect = ref([]);
    return {
      revenueDaysSelect,
      memberDaysSelect,
      channelDaysSelect,
      subscribersDaysSelect
    };
  }
};
</script>

<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: #f2f7ff;
  position: relative;

  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
    border-radius: 8px;
    .chart-wrapper-nav{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
  }
}

.export-all-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
