
import { defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import {
  getDashboardChannelList,
  DashboardChannelListResponse
} from '@/services/api';
import dayjs from 'dayjs';

export default defineComponent({
  props: {
    /**
     * select_date
     * @type Array [endDate,startDate]
     */
    selectDate: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const isLoading = ref(false);
    const page = ref(1);
    const data = ref<DashboardChannelListResponse>({});
    const { t } = useI18n();

    const now = dayjs().format('YYYY.MM.DD');
    const sixDaysBefore = dayjs().subtract(6, 'day').format('YYYY.MM.DD');
    watch(
      [page, () => props.selectDate],
      async([pageValue, selectDate]) => {
        isLoading.value = true;
        if (selectDate.length === 0) return;
        const res = await getDashboardChannelList({
          query: {
            page: page.value,
            startDate: `${selectDate[1]}`,
            endDate: `${selectDate[0]}`
          }
        });

        data.value = res;
        isLoading.value = false;
      },
      {
        immediate: true,
        deep: true
      }
    );

    return {
      isLoading,
      page,
      data,
      now,
      t,
      sixDaysBefore
    };
  }
});
